import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomeContent from '../components/HomeContent.vue'
import FindUser from '../views/FindUser.vue'
import FindCompany from '../views/FindCompany.vue'
import MobileTerminal from '../views/MobileTerminal.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '智慧商变'
    },
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'HomeContent',
        component: HomeContent,
      },
      {
        path: '/FindUser',
        name: 'FindUser',
        component: FindUser,
      },
      {
        path: '/FindCompany',
        name: 'FindCompany',
        component: FindCompany
      },
     
    ]
  },
  {
    path:'/MobileTerminal',
    name:'MobileTerminal',
    component:MobileTerminal
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

