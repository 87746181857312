<template>
  <div class="bodybox">
    <div><img src="../assets/appImage/head.png" style="width: 100%" /></div>
    <div style="width: 100%; height: 4px; background: #ededed"></div>
    <div style="text-align: center">
      <img
        class="ticketTitle"
        src="../assets/appImage/ticketTitle.png"
        style="width: 50%"
      />
    </div>
    <div class="ticketContent">
      <img
        src="../assets/appImage/ticketOne.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/ticketTwo.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/ticketThree.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/ticketFour.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/ticketFive.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/ticketSix.png"
        style="width: 95%; margin-bottom: 10px"
      />
    </div>
    <div style="width: 100%; height: 4px; background: #ededed"></div>

    <div style="text-align: center">
      <img
        class="reimburseTitle"
        src="../assets/appImage/reimburseTitle.png"
        style="width: 35%"
      />
    </div>
    <div class="reimburseContent">
      <img
        src="../assets/appImage/reimburseOne.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/reimburseTwo.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/reimburseThree.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/reimburseFour.png"
        style="width: 95%; margin-bottom: 10px"
      />
      <img
        src="../assets/appImage/reimburseFive.png"
        style="width: 95%; margin-bottom: 10px"
      />
    </div>
    <div style="width: 100%; height: 4px; background: #ededed"></div>
    <div style="text-align: center">
      <img
        class="courseTitle"
        src="../assets/appImage/courseTitle.png"
        style="width: 50%"
      />
    </div>
    <div class="courseContent">
      <img
        src="../assets/appImage/course.png"
        style="width: 95%; margin-bottom: 10px"
      />
    </div>
    <div style="width: 100%; height: 4px; background: #ededed"></div>
    <div style="text-align: center">
      <img
        class="policyTitle"
        src="../assets/appImage/policyTitle.png"
        style="width: 70%"
      />
    </div>
    <div class="policyContent">
      <img
        src="../assets/appImage/policy.png"
        style="width: 95%; margin-bottom: 10px"
      />
    </div>
    <div style="width: 100%; height: 4px; background: #ededed"></div>
    <div style="text-align: center">
      <img
        class="certificateTitle"
        src="../assets/appImage/certificateTitle.png"
        style="width: 25%"
      />
    </div>
    <a-carousel autoplay>
      <div style="width: 100%; text-align: center">
        <img
          src="../assets/appImage/certificateOne.png"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div style="width: 100%; text-align: center">
        <img
          src="../assets/appImage/certificateTwo.jpg"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div style="width: 100%; text-align: center">
        <img
          src="../assets/appImage/certificateThree.jpg"
          style="width: 50%; margin: 0 auto"
        />
      </div>
      <div style="width: 100%; text-align: center">
        <img
          src="../assets/appImage/certificateFour.jpg"
          style="width: 50%; margin: 0 auto"
        />
      </div>
    </a-carousel>
    <div class="footer">
      <a-row>
        <a-col :span="18">
          <div style="margin-left:10px;margin-top:30px;color:#666666">电话：0375-3883029</div>
          <div style="margin-left:10px;color:#666666">地址：河南省平顶山市中兴路华诚大厦9层</div>
        </a-col>
        <a-col :span="6">
            <img
        src="../assets/appImage/QRCode.jpg"
        style="width: 80%;margin-top:5px"
      />
      <div style="letter-spacing: 4px;color:#666666">官方微信</div>
        </a-col>
      </a-row>
      <div style="text-align:center">
    <span style="letter-spacing: 5px;color:black;font-size:15px;">河南用心网络科技有限公司</span>
    </div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped>
.bodybox {
  width: 100%;
}
.ticketTitle {
  margin-top: 30px;
  margin-bottom: 10px;
}
.ticketContent {
  text-align: center;
}
.reimburseTitle {
  margin-top: 20px;
  margin-bottom: 10px;
}
.reimburseContent {
  text-align: center;
}
.courseTitle {
  margin-top: 20px;
  margin-bottom: 10px;
}
.courseContent {
  text-align: center;
}
.policyTitle {
  margin-top: 20px;
  margin-bottom: 10px;
}
.policyContent {
  text-align: center;
}
.certificateTitle {
  margin-top: 20px;
  margin-bottom: 10px;
}
.footer {
  width: 100%;
  background: #ededed;
  font-size: 14px !important;
  font-family: "微软雅黑";
  font-style: normal;
  font-weight: 800 !important;
  text-decoration: none;
}
</style>
