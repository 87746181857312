<template>
  <!-- 首页欢迎图 -->
  <div class="home-content-pic">
    <a-image width="99vw" :preview="false" src="/companyindex/bj2.jpg" />
  </div>
  <!-- 首页功能介绍 -->
  <!-- <accountAgency /> -->
  <finical />
  <reimbursement />
  <!-- 首页公司介绍 -->
  <div class="home-content-desc">
    <comoanyDesc />
  </div>
  <!-- 首页新闻政策 -->
  <div class="home-content-news">
    <news />
  </div>
  <!-- 首页证书 -->
  <div class="home-content-cert">
    <cert />
  </div>
</template>

<script setup>
import { ref } from "vue";
import finical from "./homeContent/finical.vue";
import reimbursement from "./homeContent/reimbursement.vue";
import accountAgency from "./homeContent/accountAgency.vue";
import comoanyDesc from "./homeContent/companyDesc.vue";
import news from "./homeContent/news.vue";
import cert from "./homeContent/cert.vue";
import contact from "./homeContent/contact.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const MonitorResolution = () => {
  let str = window.innerWidth;
  if (str > 750) {
    return;
  } else {
    router.push("/MobileTerminal");
  }
};
MonitorResolution()
</script>
